<template>
  <div>
    <h2 class="jsmb-title-detail has-text-text is-size-4">mes favoris</h2>
    <p v-if="bookmarkSpaces.length === 0">Vous n'avez pas de favori.</p>
    <div class="favorite-looper" v-for="(bookmarkSpace, index) in bookmarkSpaces" :key="'bookmarkSpace-'+index">
      <div class="columns jsmb-favorite">
        <div class="column is-half-desktop is-full-mobile jsmb-favorite-photo" :style="'background:url('+bookmarkSpace.picture+') no-repeat center center / cover'">
          <router-link :to="{name: 'Detail', params: {id:bookmarkSpace.space_id, city:bookmarkSpace.city.toLowerCase()}}" class="jsmb-card-link" tag="a" push></router-link>
        </div>
        <div class="column is-half-desktop is-full-mobile jsmb-favorite-detail" >
          <div class="columns">
            <div class="column is-12">
              <strong class="jsmb-size">{{ bookmarkSpace.surface }} m²</strong>
              <p class="jsmb-favorite-type is-uppercase">{{bookmarkSpace.space_type}} / EMPLACEMENT {{bookmarkSpace.location_type}}</p>
              <router-link :to="{name: 'Detail', params: {id:bookmarkSpace.space_id, city:bookmarkSpace.city.toLowerCase()}}" tag="a" push>
                <h4 class="jsmb-favorite-type-area">
                  {{bookmarkSpace.announcement_title}} - {{bookmarkSpace.space_title}}
                </h4>
              </router-link>
              <p class="jsmb-favorite-adress">{{bookmarkSpace.city}} - {{bookmarkSpace.zip_code}}</p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <p class="jsmb-favorite-prix" v-if="isPro"><span>{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(bookmarkSpace.price_ht)}}</span> HT/jour/m²</p>
              <p class="jsmb-favorite-prix"><span>{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(bookmarkSpace.price_ttc)}}</span> TTC/jour/m²</p>
            </div>
          </div>
        </div>
      </div>
      <div class="columns has-text-right jsmb-favorite-erase">
          <div class="column is-12"><button class="button is-danger" @click="removeBookmarkSpace(bookmarkSpace.space_id, index)">Supprimer</button></div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_BOOKMARK_SPACE, REMOVE_BOOKMARK_SPACE } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'Favorite',
  data () {
    return {
      windowWidth: '',
      isPro: JSON.parse(localStorage.getItem('is-professional')) || false,
      bookmarkSpaces: [],
    }
  },
  created () {
    // Get BookmarkSpace
    this.$store.dispatch(
      GET_BOOKMARK_SPACE
    ).then(
      resp => {
        this.bookmarkSpaces = resp
      }
    )
  },
  mounted () {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    })
  },
  methods: {
    getWindowWidth: function(event) {
      document.querySelectorAll('.favorite-looper').forEach(function(element){

      var height = element.querySelector('.jsmb-favorite-detail'),
      targetDiv = element.querySelector('.jsmb-favorite-photo'),
      applyHeight = height.offsetHeight;

      targetDiv.style.height = applyHeight+"px";
      })
    },
    removeBookmarkSpace: function(space_id, bookmarkSpaceIndex) {
      // Remove BookmarkSpace
      this.$store.dispatch(
        REMOVE_BOOKMARK_SPACE,
        {space_id: space_id}
      ).then(
        resp => {
          const {success} = resp.data.response
          if (success) {
            this.bookmarkSpaces.splice(bookmarkSpaceIndex, 1)
          }
        }
      )
    }
  }
    
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .jsmb-favorite {
    border:2px solid #b9bbc0;
    /* cursor: pointer;  */
  }
  .jsmb-favorite-photo,
  .jsmb-favorite-detail {
    padding:3rem;
  }
  .jsmb-size {
  }
  .jsmb-favorite-type {
    color:#818080;
    margin-top: 1.5rem;
  }
  .jsmb-favorite-type-area {
    color:#1ac3ba;
    font-size:1.2rem;
    font-weight: bold;
  }
  .jsmb-card-link {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
  .jsmb-favorite-prix {
    font-size:1.2rem;
    margin-top: 1.5rem;
  }
  .jsmb-favorite-prix span {
    font-weight: bold;
  }
  .jsmb-favorite-erase {
    padding:2rem;
    padding-right:0;
  }
  @media screen and (min-width:320px) and (max-width:1049px) {
  .jsmb-favorite-detail {
    padding:1rem;
  }
  }
</style>
